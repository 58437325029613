import React from 'react';
// @ts-ignore
import { Player } from '@vinivia/player';
import './App.css';

function App() {

  const viewUrl = "DIGEST:eyJhcHBsaWNhdGlvbklkIjoidmluaXZpYS5jb20iLCJkaWdlc3QiOiJRZzNvTUdqUXVlT1JiR21ZRUFOUDNHMlVrTUgzay8rNktXekZ6Y0swTitGaE9ld0NyUnVIamNFNmc3b25HVU9tWVRKWTk4R0tQTXJlMVNBQ1ZwUjRNQT09IiwidG9rZW4iOiJ7XCJleHBpcmVzXCI6MTcwOTc1MjU1NDM3OSxcInVyaVwiOlwiaHR0cHM6Ly9wY2FzdC5waGVuaXhydHMuY29tXCIsXCJyZXF1aXJlZFRhZ1wiOlwiY2hhbm5lbElkOmV1cm9wZS13ZXN0MiN2aW5pdmlhLmNvbSN0b3JzdGVuLnNFUlNvZVJWenoyYVwifSJ9";

  return (
    <main className="page-container">
      <section className="player-container">
        <Player
          viewUrl={viewUrl}
          alias="torsten-asks"
        />
      </section>
    </main>
  );
}

export default App;
